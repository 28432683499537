import { Image } from '$shared/components';
import { Variants } from 'framer-motion';
import React, { memo } from 'react';
import { PromotedNavigationNode } from '~/lib/data-contract';
import { Link } from '~/shared/components/Link';
import { ConditionalWrap } from '~/shared/utils/jsx';
import { TextBlockForContentCards } from '~/templates/blocks/components/TextBlockForContentCards';
import { queriesIsolated } from '~/theme';
import {
    StyledPromotedContent,
    StyledPromotedImageWrapper,
    StyledPromotedItemContent,
} from './styled';

/**
 * A list of link images, with optional texts
 */
export const PromotedChildren = memo(
    ({ nodes, variants }: { nodes?: PromotedNavigationNode[]; variants?: Variants }) => {
        return (
            <StyledPromotedContent variants={variants} itemLength={nodes?.length}>
                {nodes?.map(({ image, link, text }, i) => {
                    // The last image gets a smaller width
                    const isLastItem = i === 1;
                    const imageSize = {
                        w: isLastItem ? 280 : 420,
                        h: 365,
                    };
                    const sizes = `
                    ${queriesIsolated.xs} 50vw,
                    ${queriesIsolated.lg} 30vw,
                    ${isLastItem ? '15vw' : '22vw'}`;

                    return (
                        <ConditionalWrap
                            key={i}
                            if={Boolean(link?.url)}
                            wrap={(itemContent) => (
                                <Link
                                    href={link?.url || ''}
                                    passHref={true}
                                    prefetch={false}
                                    {...link}
                                    children={itemContent}
                                    type="router"
                                />
                            )}
                        >
                            <StyledPromotedItemContent>
                                {image?.src && (
                                    <StyledPromotedImageWrapper {...imageSize}>
                                        <Image
                                            {...image}
                                            src={image.src}
                                            cW={imageSize.w}
                                            cH={imageSize.h}
                                            layout="fill"
                                            objectFit="cover"
                                            sizes={sizes}
                                            hoverStyle="both"
                                            onLoadAnimation="none"
                                            showSpinner={false}
                                            skeletonShade="none"
                                        />
                                    </StyledPromotedImageWrapper>
                                )}
                                {text && (
                                    <TextBlockForContentCards
                                        headline={text}
                                        headlineVariant="display3"
                                        moduleTheme={{ headlineShade: 'headlineInverted' }}
                                    />
                                )}
                            </StyledPromotedItemContent>
                        </ConditionalWrap>
                    );
                })}
            </StyledPromotedContent>
        );
    }
);
