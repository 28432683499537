import React from 'react';
import Basket from '$icons/solidAsh/shopping-cart.svg';
import { useBasket, MiniBasket } from '~/features/basket';
import { useTranslation } from '~/shared/utils/translation';
import { queries } from '~/theme';
import { StyledMetaButton, StyledIconBadge } from '../styled';
import { useFrame } from '~/shared/utils';
import { RouterLink, Tooltip } from '~/shared/components';
import { useMedia } from 'react-use';
import { useTheme } from '@emotion/react';
import { VisuallyHidden } from '~/shared/components/VisuallyHidden';

export const MiniBasketButton = () => {
    const { traits } = useTheme();
    const { data: basket, isMiniBasketOpen, totalQuantity } = useBasket();
    const { data } = useFrame();
    const { translate } = useTranslation();
    const isMobile = !useMedia(queries.md, false);

    const { lines = [] } = basket || {};

    const basketTrigger = (
        <Tooltip content={translate('navigation.mainMenu.basket')} describedBy="basket-tooltip">
            <RouterLink href={data?.staticLinks?.basketPage?.url || ''} passHref>
                <StyledMetaButton active={isMiniBasketOpen} as="a">
                    <VisuallyHidden>{translate('navigation.mainMenu.basket')}</VisuallyHidden>
                    <Basket aria-hidden="true" />
                    {lines?.length ? (
                        <StyledIconBadge
                            backgroundColor={traits.background.n21mainMenu}
                            children={totalQuantity}
                        />
                    ) : null}
                </StyledMetaButton>
            </RouterLink>
        </Tooltip>
    );

    return <MiniBasket triggerComponent={basketTrigger} scrollLock={isMobile} />;
};
