import { useState, useEffect } from 'react';

export const useHeaderHeight = (isOpen: boolean) => {
    const [headerHeight, setHeaderHeight] = useState<number>(0);

    useEffect(() => {
        const calculateHeaderHeight = () => {
            const header = document.documentElement.querySelector('header'); // Somewhat fragile, but handles different menu components on different themes.
            const visibleHeaderHeight = header?.getBoundingClientRect().bottom || 0;
            setHeaderHeight(Math.max(visibleHeaderHeight, 0));
        };

        const observer = new window.ResizeObserver((entries) => {
            if (entries[0]) {
                calculateHeaderHeight();
            }
        });

        if (isOpen) {
            calculateHeaderHeight();
            observer.observe(document.body);
        } else {
            observer.unobserve(document.body);
        }

        return () => {
            observer.disconnect();
        };
    }, [isOpen]);

    return headerHeight;
};
