import styled from '@emotion/styled';
import { Button } from '~/shared/components/Button';
import { dialogHeaderHeight } from '~/shared/components/Overlay';
import { indicatePending } from '~/shared/utils/styled';
import { breakpoints } from '~/theme';

export const StyledActionField = styled.div(({ theme }) => ({
    display: 'flex',
    paddingBottom: theme.spaces[2],
}));

export const StyledRichTextField = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.light30,
    padding: theme.spaces[3],
    marginTop: theme.spaces[4],
}));

export const StyledSignInForm = styled.form<{ pending?: boolean; headerHeight: number }>(
    ({ theme, pending, headerHeight }) => ({
        display: 'flex',
        flexDirection: 'column',
        padding: `${theme.spaces[4]} ${theme.spaces[3]}`,
        gap: theme.spaces[3],
        ...(pending ? indicatePending : {}),
        overflowY: 'auto',
        maxHeight: `calc(100dvh - ${dialogHeaderHeight}px - ${headerHeight}px)`,

        '@supports (not (height: 1dvh))': {
            maxHeight: `calc(100vh - ${dialogHeaderHeight}px - ${headerHeight}px)`,
            // Increase padding bottom by approximately URL bar height to compensate on 'dvh' unsupported browsers
            paddingBottom: `calc(${theme.spaces[4]} + 60px)`,

            [breakpoints.md]: {
                paddingBottom: theme.spaces[4],
            },
        },
    })
);

export const StyledSignUpButton = styled(Button)({
    backgroundColor: 'transparent',
    width: '100%',
});

export const StyledForgotPasswordButton = styled(Button)(({ theme }) => ({
    fontWeight: theme.fontWeights.bold,
}));
