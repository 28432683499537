import React, { memo } from 'react';
import { StyledSoMeItems } from './styled';
import { weakKey } from '~/shared/utils/jsx';
import { SocialLink } from '~/lib/data-contract';
import { Image, Link } from '~/shared/components';

export const SoMeItems = memo(({ items }: { items: SocialLink[] }) => (
    <StyledSoMeItems>
        {items.map(({ link, image }) =>
            link ? (
                <Link
                    prefetch={false}
                    href={link.url || ''}
                    target={link.target || '_blank'}
                    title={link.text}
                    rel="noopener nofollow"
                    type="plain"
                    key={weakKey(link)}
                >
                    {image?.src && (
                        <Image
                            src={image.src}
                            width={24}
                            height={24}
                            alt={image.alt}
                            layout="intrinsic"
                        />
                    )}
                </Link>
            ) : null
        )}
    </StyledSoMeItems>
));
