import React, { useCallback, useState } from 'react';
import {
    StyledActionField,
    StyledRichTextField,
    StyledSignInForm,
    StyledSignUpButton,
    StyledForgotPasswordButton,
} from './styled';
import { Button, InputField, RawHtml } from '~/shared/components';
import { PasswordToggle } from '~/shared/components/FormElements';
import { useForm, useFrame, useTranslation } from '~/shared/utils';
import { useClub } from '~/features/club';
import { useHeaderHeight } from '~/shared/hooks';
import { SocialLogin } from './components';

export const SignIn = () => {
    const {
        register,
        formState: { errors },
        watch,
        handleSubmit,
    } = useForm({
        validationSchema: 'signIn',
    });
    const { translate } = useTranslation();
    const {
        loginPage,
        toggleSignInActive,
        toggleSignUpActive,
        signInUser,
        toggleResetPasswordActive,
    } = useClub();

    const headerHeight = useHeaderHeight(true);

    const { data: frame } = useFrame();
    const [signInIsLoading, setSignInIsLoading] = useState(false);
    const callbackUrl = frame?.staticLinks?.clubPage?.url;

    const { signUpDescription } = loginPage || {};

    const [credential, password] = watch(['credential', 'password']);

    const switchSignInSignUp = useCallback(() => {
        toggleSignInActive(false);
        toggleSignUpActive(true);
    }, [toggleSignInActive, toggleSignUpActive]);

    const switchSignInResetPassword = useCallback(() => {
        toggleSignInActive(false);
        toggleResetPasswordActive(true);
    }, [toggleResetPasswordActive, toggleSignInActive]);

    const onSubmit = async ({
        credential,
        password,
    }: {
        credential?: string;
        password?: string;
    }) => {
        setSignInIsLoading(true);

        const isEmail = credential?.includes('@');
        const signInInformation = {
            ...(isEmail ? { email: credential } : { phone: credential }),
            password,
        };
        const signInSuccess = await signInUser(signInInformation, callbackUrl);

        if (signInSuccess) {
            toggleSignInActive(false);
        }
        setSignInIsLoading(false);
    };

    return (
        <StyledSignInForm
            onSubmit={handleSubmit(onSubmit)}
            pending={signInIsLoading}
            headerHeight={headerHeight}
        >
            <InputField
                {...register('credential')}
                invalidMessage={errors.credential?.message}
                isActive={!!credential}
                autoComplete="email"
                label={translate('form.label.emailOrPhone')}
                placeholder={translate('form.placeholder.emailOrPhone')}
            />
            <PasswordToggle
                {...register('password')}
                invalidMessage={errors.password?.message}
                isActive={!!password}
                label={translate('form.label.password')}
                placeholder={translate('form.placeholder.password')}
                type="password"
            />

            <StyledActionField>
                <StyledForgotPasswordButton
                    onClick={switchSignInResetPassword}
                    variant="tertiary"
                    size="md"
                    type="button"
                    showHoverIndicator={false}
                    disabled={signInIsLoading}
                >
                    {translate('club.signIn.forgotPassword')}
                </StyledForgotPasswordButton>
            </StyledActionField>

            <Button disabled={signInIsLoading}>{translate('club.signIn.login')}</Button>
            <SocialLogin disabled={signInIsLoading} />

            <StyledRichTextField>
                <RawHtml html={signUpDescription} />
                <StyledSignUpButton
                    onClick={switchSignInSignUp}
                    variant="secondary"
                    type="button"
                    disabled={signInIsLoading}
                >
                    {translate('club.signIn.signUpButton')}
                </StyledSignUpButton>
            </StyledRichTextField>
        </StyledSignInForm>
    );
};
